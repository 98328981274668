@import "settings";

.h-same-height {
    display: flex;
    flex-wrap: wrap;
}
.h-vertical-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.h-space-between {
    display: flex;
    justify-content: space-between;
}
.h-flex-grow-1 {
    flex-grow: 1 !important;
}
.h-height-100 {
    height: 100% !important;
}
.h-width-100 {
    width: 100% !important;
}
.h-width-auto {
    width: auto !important;
}
.h-clear-none {
    clear: none !important;
}
.h-cursor-pointer {
    cursor: pointer !important;
}
// text
.h-text-regular {
    font-weight: 400 !important;
}
.h-text-semibold {
    font-weight: 500 !important;
}
.h-text-bold {
    font-weight: 600 !important;
}

.h-text-uppercase {
    text-transform: uppercase !important;
}
.h-text-lowercase {
    text-transform: lowercase !important;
}
.h-text-capitalize {
    text-transform: capitalize !important;
}
.h-text-size-medium {
    @include medium {
        font-size: 1.125rem !important;
    }
}
.h-text-size-default {
    font-size: 1rem !important;
}
.h-text-size-small {
    font-size: .875rem !important;
}
.h-text-size-tiny {
    font-size: .75rem !important;
}
// text align
.h-text-center {
    text-align: center !important;
}
.h-text-right {
    text-align: right !important;
}
.h-text-left {
    text-align: left !important;
}
.h-vertical-align-middle {
    vertical-align: middle !important;
}
.h-vertical-align-top {
    vertical-align: top !important;
}
.h-vertical-align-bottom {
    vertical-align: bottom !important;
}
// white space
.h-text-nowrap {
    white-space: nowrap !important;
}
// display
.h-display-none {
    display: none !important;
}
.h-display-inline-block {
    display: inline-block !important;
}
.h-display-block {
    display: block !important;
}
.h-display-flex {
    display: flex !important;
}
// position
.h-relative {
    position: relative !important;
}

.h-border-none {
    border: none !important;
}
.h-border-radius {
    border-radius: $global-radius !important;
}
// background & color
.h-background-white {
    background-color: $white !important;
}
.h-background-black {
    background-color: $black !important;
}
.h-background-primary {
    background-color: $primary-color !important;
}
.h-background-secondary {
    background-color: $secondary-color !important;
}
.h-background-tertiary {
    background-color: $tertiary-color !important;
}
.h-background-dark-gray {
    background-color: $dark-gray !important;
}
.h-background-medium-gray {
    background-color: $medium-gray !important;
}
.h-background-lightest-gray {
    background-color: $lightest-gray !important;
}
.h-background-light-gray {
    background-color: $light-gray !important;
}
.h-background-alert {
    background-color: $alert-color !important;
}
.h-background-success {
    background-color: $success-color !important;
}
.h-background-body {
    background-color: #FAFAFA !important;
}
.h-box-shadow {
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.12) !important;
}

.h-color-white {
    color: $white !important;
}
.h-color-black {
    color: $black !important;
}
.h-color-primary {
    color: $primary-color !important;
}
.h-color-secondary {
    color: $secondary-color !important;
}
.h-color-tertiary {
    color: $tertiary-color !important;
}
.h-color-light-gray {
    color: $light-gray !important;
}
.h-color-medium-gray {
    color: $medium-gray !important;
}
.h-color-dark-gray {
    color: $dark-gray !important;
}
.h-color-alert {
    color: $alert-color !important;
}

// others
.h-pure-button {
    border: none;
    background-color: transparent;
    display: block;
    width: 100%;
    padding: 0;
}

.free-lesson-message {
    font-family: 'Metropolis', sans-serif;
    font-size: 1.4rem;
    @include medium {
        font-size: 1.8rem;
    }
    text-transform: uppercase;
    color: white;
    line-height: 1.5;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid #A8CD00;
    background: #A8CD00
}

.basket-button {
    width: 3rem!important;
    height: 2rem!important;
    background-size: auto!important;
    filter: brightness(0) saturate(100%) invert(74%) sepia(55%) saturate(3041%) hue-rotate(27deg) brightness(102%) contrast(102%);
}

.basket-item-counter {
    color: #00A2EA;
    font-weight: bold;
    margin-left: -1.2rem;
    margin-top: 1.2rem;
}

.basket-item-price {
    color: red;
    font-weight: bold;
}

.basket-button:hover {
    background-color: transparent!important;
}

@include medium {
    
    .h-display-flex-medium {
        display: flex;
    }
    
}

$mp-prefix: h;
// height & width
$sizes: (
    w: max-width,
    h: max-height
);
$sizesHalf: (
    '': 0,
    5: 0.5rem,
);
@each $key, $value in $sizes {
    @for $i from 0 through 6 {
        @each $halfKey, $halfValue in $sizesHalf {
            .#{$mp-prefix}-#{$value}-#{$i}#{$halfKey} {
                #{$value}: $i * 1rem + $halfValue !important
            }
        }
    }
}

// margin & padding
$keys: (
    m: margin,
    p: padding
);
$half: (
    '': 0,
    5: 0.5rem,
    25: 0.25rem,
    75: 0.75rem
);

@each $key, $value in $keys {
    @for $i from 0 through 9 {
        @each $halfKey, $halfValue in $half {
            .#{$mp-prefix}-#{$key}-#{$i}#{$halfKey} {
                #{$value}: $i * 1rem + $halfValue !important
            }
            .#{$mp-prefix}-#{$key}x-#{$i}#{$halfKey} {
                #{$value}-left: $i * 1rem + $halfValue !important;
                #{$value}-right: $i * 1rem + $halfValue !important
            }
            .#{$mp-prefix}-#{$key}y-#{$i}#{$halfKey} {
                #{$value}-top: $i * 1rem + $halfValue !important;
                #{$value}-bottom: $i * 1rem + $halfValue !important;
            }
            .#{$mp-prefix}-#{$key}t-#{$i}#{$halfKey} {
                #{$value}-top: $i * 1rem + $halfValue !important
            }
            .#{$mp-prefix}-#{$key}b-#{$i}#{$halfKey} {
                #{$value}-bottom: $i * 1rem + $halfValue !important
            }
            .#{$mp-prefix}-#{$key}r-#{$i}#{$halfKey} {
                #{$value}-right: $i * 1rem + $halfValue !important
            }
            .#{$mp-prefix}-#{$key}l-#{$i}#{$halfKey} {
                #{$value}-left: $i * 1rem + $halfValue !important
            }
        }
    }
}

@include small-only {
    
    @each $key, $value in $keys {
        @for $i from 0 through 9 {
            @each $halfKey, $halfValue in $half {
                .#{$mp-prefix}-#{$key}-sm-#{$i}#{$halfKey} {
                    #{$value}: $i * 1rem + $halfValue !important
                }
                .#{$mp-prefix}-#{$key}x-sm-#{$i}#{$halfKey} {
                    #{$value}-left: $i * 1rem + $halfValue !important;
                    #{$value}-right: $i * 1rem + $halfValue !important
                }
                .#{$mp-prefix}-#{$key}y-sm-#{$i}#{$halfKey} {
                    #{$value}-top: $i * 1rem + $halfValue !important;
                    #{$value}-bottom: $i * 1rem + $halfValue !important;
                }
                .#{$mp-prefix}-#{$key}t-sm-#{$i}#{$halfKey} {
                    #{$value}-top: $i * 1rem + $halfValue !important
                }
                .#{$mp-prefix}-#{$key}b-sm-#{$i}#{$halfKey} {
                    #{$value}-bottom: $i * 1rem + $halfValue !important
                }
                .#{$mp-prefix}-#{$key}r-sm-#{$i}#{$halfKey} {
                    #{$value}-right: $i * 1rem + $halfValue !important
                }
                .#{$mp-prefix}-#{$key}l-sm-#{$i}#{$halfKey} {
                    #{$value}-left: $i * 1rem + $halfValue !important
                }
            }
        }
    }
    
}
