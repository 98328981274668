@import '../../styles/settings';

.payments-instructions {
    display: flex;
    @include small-only {
        flex-direction: column;
        justify-content: center;
        img {
            margin-bottom: 20px;
        }
    }
}
