@import '../../styles/settings';

.notification-wrapper {
    position: relative;
    display: block;
    height: 0;
    transition: height 0.3s;
    z-index: 9;

    &.is-open {
        height: rem-calc(66);

        .notification {
            height: auto;
            padding: 1.25rem 0;
            @include large {
                top: rem-calc(71);
            }
        }

        .notification-timeout {
            width: 0;
            background-color: rgba($white, .5);
            transition: width 10s linear;
        }
    }

    &.is-static {
        height: auto;
        z-index: 8;

        .notification {
            position: relative;
            top: 0;
        }
    }

    &.is-error {
        .notification {
            background-color: $alert-color;
        }
    }
    &.is-success {
        .notification {
            background-color: $primary-color;
        }
    }
    &.is-info {
        .notification {
            background-color: $tertiary-color;
        }
    }
}

.notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    transition: top 0.3s, padding 0.3s;

    & > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.notification-text {
    font-size: 1rem;
    font-weight: 700;
    color: $white;
    padding-left: 2rem;
    padding-right: 1rem;
    background-size: 1.25rem 1.25rem;
    background-position: 0 center;
    background-repeat: no-repeat;
}

.notification-close {
    &:hover {
        background-color: rgba($white, 0.15);
    }
}

.notification-timeout {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: transparent;
    transition: width 0s linear;
}
