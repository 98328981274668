@import "../../styles/settings";

label:not(.is-title), .label {
    display: inline-block;
    font-size: 1rem;
    color: $header-color;
    text-transform: uppercase;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    margin-bottom: .25rem;
}