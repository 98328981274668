@import '../../styles/settings';
@import '../../styles/mixins';

.footer {
    padding: .75rem 0;
    border-top: 1px solid $lightest-gray;
}
.footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer-menu {
    @include horizontal-list;
    text-align: center;

    li {
      margin: 0 1rem;
      @include medium-down {
        margin-bottom: .5rem;
      }
    }

    a {
        font-size: .875rem;
        color: $dark-gray;

        &:hover {
            text-decoration: underline;
        }
    }
}


@include small-only {
    
    .footer-wrapper {
        display: block;
        text-align: center;

        & > *:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
    
}