@import "../../styles/settings";
@import "../../styles/mixins";

.error-box {
    @include reset-list;
    background-color: rgba($alert-color, .1);
    color: $alert-color;
    padding: 1.5rem;
    padding-bottom: 1rem;
    border-radius: $global-radius;
    margin: 1rem 0;

    li {
        margin-bottom: .5rem;
        font-size: .875rem;

        &::before {
            background-color: $alert-color !important;
        }
    }
}