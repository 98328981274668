@import '../../styles/settings';

.back-button {
    position: fixed;
    width: 3rem;
    height: 3rem;
    top: 5.75rem;
    left: 1.5rem;
    border-radius: $global-radius;
    background-color: $white;
    box-shadow: $box-shadow;
    transition: background-color .3s, box-shadow .3s;

    &:hover {
        box-shadow: none;
    }
}

@include small-only {
    
    .back-button {
        display: none;
    }
    
}