@import "../../styles/settings";

.cta-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: calc(100% - #{$global-gap-small});
    border-radius: $global-radius;
    overflow: hidden;
    padding: 1.5rem;
    margin-bottom: $global-gap-small;

    &.is-primary {
        background-color: $primary-color;
    }
    &.is-secondary {
        background-color: $secondary-color;
    }

    .button {
        position: relative;
        margin-top: auto;
        padding: 0;
        padding-right: .375rem;
        border-radius: 6px;
        color: $white;
        text-align: left;
        border: none !important;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -1rem;
        right: -1rem;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: $white;
        background-image: url('../../assets/images/icons/ic-owl.svg');
        background-size: 60% 60%;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
    }
}
.cta-card-title {
    color: $white;
    text-transform: uppercase;
    margin-bottom: .5rem;
    line-height: 1;
}
.cta-card-text {
    position: relative;
    color: $white;
    width: 80%;
    border-radius: 6px;
    z-index: 1;
}

@include large {
    
    .cta-card-title {
        font-size: 3rem;
    }
    
}