@import '../settings';
@import '../mixins';
@import 'flex-grid';
@import '../general/fonts';
@import '../general/icons';

html {
    box-sizing: border-box;
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: $body-background;
    font-family: $body-font-family;
    font-weight: 400;
    line-height: $global-lineheight;
    color: $body-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    outline: none;
}

hr {
    border: none;
    height: 1px;
    background-color: $light-gray;
    margin: 1rem 0;
}

/* --------------
- Typography
----------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: $header-color;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    line-height: $header-lineheight;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

h3 {
    margin-bottom: .75rem;
    
    &.icon-title {
        padding-left: 2.25rem;
        background-size: 1.5rem 1.375rem;
        background-position: left top;
        background-repeat: no-repeat;
    }
}

$headers-map: (
    'h1': $header-h1-fontsize,
    'h2': $header-h2-fontsize,
    'h3': $header-h3-fontsize,
);

@include large {
    @each $header, $style in $headers-map {
        #{$header}, .#{$header} {
            font-size: map-get($style, "large") / 16 * 1rem;
        }
    }
}
@include medium-only {
    @each $header, $style in $headers-map {
        #{$header}, .#{$header} {
            font-size: map-get($style, "medium") / 16 * 1rem;
        }
    }
}
@include small-only {
    @each $header, $style in $headers-map {
        #{$header}, .#{$header} {
            font-size: map-get($style, "small") / 16 * 1rem;
        }
    }
}

.link {
    border: none;
    background-color: transparent;
    padding: 0;
    color: $secondary-color;
    text-decoration: underline;
    font-size: 1rem;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin-top: 0;
    color: $body-color;
    line-height: $global-lineheight;

    a {
        color: $secondary-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

ul:not(.is-pure-list) {
    @include reset-list;
    margin-bottom: 1rem;

    li {
        padding-left: 1.125rem;
        font-size: 1rem;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: .5rem;
            left: 0;
            width: .375rem;
            height: .375rem;
            background-color: $primary-color;
            border-radius: 50%;
        }
    }

    &.is-small {
        li {
            font-size: .875rem;

            &::before {
                top: rem-calc(6);
            }
        }
    }
}

/* --------------
- Layout
----------------- */
.page-content {
    min-height: calc(100vh - #{rem-calc(71)} - #{rem-calc(57)});
}
.page-row {
    margin-bottom: 2rem;
}