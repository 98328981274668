@import "../../styles/settings";

.test-question-input {

    &.is-correct {
        background-color: $light-primary-color;
        border-color: $primary-color;
        background-image: url('../../assets/images/icons/ic-check-primary.svg');
        background-size: 1.25rem 1.25rem;
        background-position: calc(100% - 1.5rem) center;
        background-repeat: no-repeat;
    }
    &.is-falsy {
        background-color: rgba($alert-color, .1);
        border-color: $alert-color !important;
        background-image: url('../../assets/images/icons/ic-close-alert.svg');
        background-size: 1rem 1rem;
        background-position: calc(100% - 1.5rem) center;
        background-repeat: no-repeat;
    }
}
