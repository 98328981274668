@import "../../styles/settings";

.icon-button {
    display: inline-block;
    vertical-align: top;
    width: 2rem;
    height: 2rem;
    border-radius: calc(#{$global-radius} / 2);
    background-color: transparent;
    background-size: 1rem 1rem;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
        background-color: $lightest-gray;
    }
}