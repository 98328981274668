@import '../../../styles/settings';

.true-false-button {
    border: none;
    position: relative;
    background-color: transparent;
    padding: 0 0.75rem;
    line-height: 1;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: $header-font-family;
    color: $light-gray;
    transition: color 0.3s;
    cursor: pointer;
    
    &::after {
        content: '';
        position: absolute;
        top: -0.375rem;
        bottom: -0.125rem;
        right: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover {
        color: $black;
    }

    &[disabled] {
        opacity: 0;
    }

    &.is-active {
        color: $black;
        &::after {
            background-image: url('../../../assets/images/icons/ic-circle-black.svg');
        }
    }

    &.is-falsy {
        opacity: 1;
        color: $alert-color;

        &::after {
            background-image: url('../../../assets/images/icons/ic-circle-alert.svg');
        }
    }
    &.is-correct {
        opacity: 1;
        color: $primary-color;

        &::after {
            background-image: url('../../../assets/images/icons/ic-circle-primary.svg');
        }
    }
}
