@import "../../styles/settings";

.checkmark-wrapper {
    min-width: rem-calc(20);
}
.checkmark {
    fill: #dfdfe5;
    transition: fill .2s;

    &.is-checked {
        fill: $primary-color;
    }
}