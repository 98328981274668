@import '../../styles/settings';

.overview-item-wrapper {
    background-color: $white;
    border-radius: $global-radius;
    border: 1px solid $light-gray;
    overflow: hidden;
    margin-bottom: 0.75rem;

    &.is-open {
        .overview-item-title {
            border-bottom-color: $light-gray;
            .button i {
                transform: rotate(180deg);
            }
        }
        .overview-item-footer {
            border-top-color: $light-gray;
            padding-top: 1.5rem;
        }
    }
}

.overview-item-title {
    padding: 1.5rem 1.75rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: border-color 0.3s;
    @include small-only {
        flex-direction: column;
        align-items: flex-start;
    }

    .button {
        margin-left: auto;
        @include small-only {
            margin-top: 1rem;
        }
        i {
            margin: 0;
            margin-left: 0.5rem;
            width: 0.75rem;
            transition: transform 0.3s;
        }
    }
}
.overview-item-title-icon {
    display: block;
    width: 6rem;
    height: 6rem;
    margin-right: 1.75rem;
    border-radius: 50%;
    background-color: $light-primary-color;
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    @include small-only {
        align-self: center;
        margin-bottom: 2rem;
        margin-right: 0;
    }
}
.overview-item-labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    @include small-only {
        flex-wrap: wrap;
        width: 100%;

        & > div:only-child {
            align-self: center;
            margin-right: 0;
            text-align: center;
            margin: 0 auto;
        }
    }
}

.overview-item-label {
    font-family: $header-font-family;
    margin-right: 2rem;
    span {
        color: $medium-gray;
        display: inline-block;
        text-transform: uppercase;
    }
    div {
        font-size: 2.25rem;
        color: $dark-gray;
    }
}

.overview-item-content {
    overflow: hidden;
    transition: height 0.3s;

    & > div {
        padding: 1.5rem 1.75rem;
    }

    @include small-only {
        .table-wrapper {
            overflow: auto;
            margin-right: -1rem;
            margin-left: -1.75rem;
    
            div {
                padding-left: 0.75rem;
            }
            
            table {
                min-width: 35rem;
            }
        }
    }


    table {
        border-collapse: collapse;
        width: 100%;
        text-align: right;

        tr {
            & *:first-child {
                text-align: left;
            }
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }

        th:not(:last-child) {
            padding-right: 1rem;
        }
        td:not(:last-child) {
            padding-right: 1rem;
        }

        th {
            color: $medium-gray;
            text-transform: uppercase;
            padding-bottom: 0.25rem;
            font-weight: 400;
            font-family: $header-font-family;
        }

        td {
            padding: 0.625rem 0;
            border-bottom: 1px solid $light-gray;
        }
    }
}

.overview-item-footer {
    padding: 1.5rem 1.75rem;
    padding-top: 0;
    border-top: 1px solid transparent;
    transition: border-color 0.3s, padding 0.3s;
}
