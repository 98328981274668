@import "../../../styles/settings";
@import "../../../styles/mixins";

.form-field-wrapper {
    position: relative;
    margin-bottom: .25rem;

    &.is-invalid {
        input[type="text"], input[type="password"], textarea, .form-select-value, .form-radio-wrapper>div, .form-checkbox {
            border-color: $alert-color;
        }
    }
}
::placeholder {
    color: rgba($body-color, .7);
}
input[type="text"], input[type="password"], textarea, .form-select-value {
    display: block;
    width: 100%;
    font-size: 1rem;
    background-color: $white;
    border-radius: $global-radius;
    color: $body-color;
    border: 1px solid $light-gray;
    padding: 1.25rem 1rem;
    line-height: 1.15;
    outline: none;
    transition: border-color .3s;

    &.is-icon {
        padding-left: 3.125rem;
    }

    &:focus {
        border-color: $primary-color;
    }
}
input:-internal-autofill-selected {
    background-color: rgba($primary-color, .2) !important;
    background: rgba($primary-color, .2) !important;
}
textarea {
    resize: vertical;
}
.form-password-show {
    position: absolute;
    top: calc(50% - 1rem);
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border: none;
    font-size: 0;
    border-radius: calc(#{$global-radius} / 2);
    background-color: transparent;
    background-size: 60% 60%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color .3s, transform .3s;

    &:hover {
        background-color: rgba($light-gray, .3);
    }
}

// SELECT
.form-select-wrapper {
    position: relative;

    &.is-reversed {
        .form-select-value {
            &.is-open {
                border-radius: 0 0 $global-radius $global-radius;
            }
        }
        .form-select-dropdown {
            bottom: 100%;
            border-radius: $global-radius $global-radius 0 0;
        }
    }

}
.form-select-value {
    &.is-open {
        border-radius: $global-radius $global-radius 0 0;
        border-color: $primary-color;
    }
    &.is-placeholder {
        color: rgba($body-color, .4);
    }
}
.form-select-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: $white;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.12);
    border-radius: 0 0 $global-radius $global-radius;
    z-index: 1;

    input {
        display: block;
        width: 100%;
        font-size: 1rem;
        padding: 1rem;
        color: $body-color;
        border: none;
    }
}
.form-select-dropdown-menu {
    @include reset-list;
    margin-bottom: 1rem;
    max-height: 15rem;
    overflow: auto;

    li {
        cursor: pointer;
        font-size: 1rem;
        color: $body-color;
        padding: .75rem 1rem;
        border: 0px solid rgba($light-gray, .5);
        border-top-width: 1px;
        transition: background-color .3s;

        &:last-child {
            border-bottom-width: 1px;
        }

        &.is-active {
            background-color: rgba($light-gray, .2);
        }
        &.is-selected {
            background-color: rgba($light-gray, .5);
        }
    }
}
.form-select-toggler, .form-select-cancel {
    position: absolute;
    top: calc(50% - 1rem);
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border: none;
    font-size: 0;
    border-radius: calc(#{$global-radius} / 2);
    background-color: transparent;
    background-image: url('../../../assets/images/icons/ic-chevron-down-gray.svg');
    background-size: 40% 40%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color .3s, transform .3s;

    &.is-open {
        transform: rotate(180deg);
    }

    &:hover {
        background-color: rgba($light-gray, .3);
    }
}
.form-select-cancel {
    right: 3.125rem;
    font-size: 1rem;
    background: none;
    color: $medium-gray;
}

// CHECKBOX | RADIO
input[type="checkbox"], input[type="radio"], input[type="file"] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    width: 0px;
    height: 0px;
    border-width: 0;
}
input[type="checkbox"]:focus+.form-checkbox, input[type="radio"]:focus+.form-radio, input[type="radio"]:focus+.checkbox-item, input[type="file"]:focus+label>.form-file-button {
    border-color: $primary-color;
}

.form-checkbox-wrapper, .form-radio-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}
.form-radio-wrapper {
    &:not(:last-child) {
        margin-bottom: .5rem;
    }
}
.form-checkbox, .form-radio {
    position: relative;
    display: inline-block;
    min-width: 1.875rem;
    height: 1.875rem;
    border: 1px solid $light-gray;
    border-radius: calc(#{$global-radius} / 2);
    background-color: $white;
    transition: background-color .3s;
}
.form-radio {
    border-radius: 50%;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        opacity: 0;
        transform: scale(.1);
        background-color: $white;
        transition: transform .3s, opacity .15s;
    }

    &.is-checked {
        background-color: $primary-color;

        &::after {
            opacity: 1;
            transform: scale(.5);
        }
    }
}
.form-checkbox {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        transform: scale(0.5);
        background-image: url('../../../assets/images/icons/ic-check-white.svg');
        background-size: 60%;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .3s, opacity .15s;
    }

    &.is-checked {
        background-color: $primary-color;

        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.form-checkbox-title, .form-radio-title {
    display: inline-block;
    margin-left: .5rem;
    margin-top: rem-calc(4);
    color: $medium-gray;

    a {
        text-decoration: underline;
    }
}

// GENERAL
.form-input-icon {
    position: absolute;
    top: calc(50% - .75rem);
    left: 1rem;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.form-input-footnote {
    text-align: right;
    font-size: .75rem;
    margin-top: rem-calc(2);
}
.form-error-messages {
    color: $alert-color;
}

label:not(.is-title), .form-label {
    display: inline-block;
    font-size: 1rem;
    color: $header-color;
    text-transform: uppercase;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    margin-bottom: .25rem;
}