@import "../../styles/settings";
@import "../../styles/mixins";

.button-dropdown-wrapper {
    display: inline-block;
    position: relative;
    z-index: 1;
}
.button-dropdown {
    @include reset-list;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: $box-shadow;
    overflow: hidden;
    border-radius: calc(#{$global-radius} / 2);
    opacity: 0;
    transform: translateY(-1rem);
    visibility: hidden;
    transition: opacity .3s, transform .3s, visibility .3s;

    &.is-open {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }

    li {
        padding: .75rem 1.5rem;
        font-weight: 500;
        color: $dark-gray;
        cursor: pointer;
        
        &:not(:last-child) {
            border-bottom: 1px solid $lightest-gray;
        }
        
        &:hover {
            background-color: $light-primary-color;
        }
    }
}