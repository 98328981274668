@import "../../styles/settings";

.label-value {
    display: inline-block;
    vertical-align: top;
}

.label-value-label {
    font-size: 1rem;
}
.label-value-value {
    font-size: 1.5rem;
    font-family: $header-font-family;
    line-height: 1.2;
}