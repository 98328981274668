@import '../../styles/settings';

.video-wrapper {
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;

    .tag {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 1;
    }
}
.video-player {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: $global-radius;
}
