@import '../../styles/settings';

.button-select {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.125rem 1rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    line-height: 1.4;
    color: $black;
    border: 1px solid transparent;
    border-radius: $global-radius;
    background-color: $white;
    box-shadow: $box-shadow;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:not(:last-child) {
        margin-bottom: 0.75rem;
    }

    &:not(.is-wrapper):not(.is-disabled):hover,
    &.is-checked {
        border-color: $primary-color;
        box-shadow: none;
    }

    &.is-wrapper {
        cursor: auto;
        user-select: text;
    }

    &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.is-falsy {
        border-color: $alert-color !important;
        background-color: rgba($alert-color, 0.1);
    }

    &.is-correct {
        border-color: $primary-color !important;
        background-color: $light-primary-color;
    }
}

.button-select-content {

    p {
        margin-bottom: 0
    }

    .left {
        
    }

    .right {
        float: right;
    }
}

.button-course-desc {
    float: left;
    font-size: 0.9rem;
    color: gray;
    padding: 0.2rem 0.5rem 0.5rem 0;
}

.free-course-message {
    font-family: "Bebas Neue", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    color: #A8CD00;
    transition: background-color .3s, color .3s, border-color .3s;
}