@import '../../styles/settings';

.test-notification-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.25rem 0;

    &.is-primary {
        background-color: $light-primary-color;

        .test-notification-icon {
            background-color: $primary-color;
        }
        .test-notification-title {
            color: $primary-color;
        }
    }
    &.is-alert {
        background-color: lighten($alert-color, 40%);

        .test-notification-icon {
            background-color: $alert-color;
        }
        .test-notification-title {
            color: $alert-color;
        }
    }
    &.is-loading {
        background-color: lighten($medium-gray, 50%);
        .test-notification-title {
            color: $medium-gray;
        }
    }
}
.test-notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.test-notification-icon {
    display: inline-block;
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.25rem;
    border-radius: $global-radius;
    background-size: 50% 50%;
    background-position: center;
    background-repeat: no-repeat;

    &.is-primary {
        background-color: $primary-color;
    }
    &.is-alert {
        background-color: $alert-color;
    }
}
.test-notification-title {
    font-weight: 700;
    font-size: 1.25rem;
}
