@import '../../styles/settings';

.right-column {
  float: right;
}

.left-column {
  float: left;
  font-family: sans-serif;
}

.left-column .lector-photo {
  display: inline-block;
  margin-bottom: 1.75rem;
  width: 20rem;
  height: 20rem;
  max-width: 100%;
}

.left-column .lector-photo img {
  border: 1px solid $light-gray;
  border-radius: $global-radius;
}

.left-column .lector-photo .lector-name {
  position: relative;
  float: left;
  width: 20rem;
  height: 3rem;
  padding-top: 1rem;
  font-weight: bold;
  margin-top: -3.1rem;
  margin-bottom: 0;
  text-align: center;
  background-color: rgba(239, 239, 241, 0.8);
  border-bottom-left-radius: $global-radius;
  border-bottom-right-radius: $global-radius;
  max-width: 100%;
}

.left-column-label {
  display: inline-block;
  font-size: 1.2rem;
  color: #4A4D4E;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.25rem;
  font-family: "Bebas Neue", sans-serif;
}

.left-column-content {
  padding-bottom: 2rem;

  .description {
    text-align: justify;
  }
}
