@import '../../styles/settings';

.result-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
}

.resulte-bar-progress {
    width: 4.5rem;
    height: 6px;
    flex-shrink: 0;
    border-radius: $global-radius;
    background-color: $lightest-gray;
    position: relative;
    margin-right: 0.5rem;

    span {
        border-radius: $global-radius;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transition: width .3s;
    }
}

.result-bar-value {
    width: 3.25rem;
    flex-shrink: 0;
    text-align: right;
}