/* bebas-neue-regular - latin-ext */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/bebas-neue-v1-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Bebas Neue Regular'), local('BebasNeue-Regular'),
       url('../../assets/fonts/bebas-neue-v1-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../assets/fonts/bebas-neue-v1-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../assets/fonts/bebas-neue-v1-latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../../assets/fonts/bebas-neue-v1-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../assets/fonts/bebas-neue-v1-latin-ext-regular.svg#BebasNeue') format('svg'); /* Legacy iOS */
}

/* Metropilis-Regular */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Metropolis-Regular.otf') format('opentype'),
}
/* Metropilis-Medium */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Metropolis-Medium.otf') format('opentype'),
}
/* Metropilis-Bold */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Metropolis-Bold.otf') format('opentype'),
}