@import "../../styles/settings";

.topbar-progress {
    display: flex;
    align-items: center;
}
.topbar-progress-value {
    display: inline-block;
    margin: 0 .875rem;
}
.topbar-progress-line {
    position: relative;
    width: 13rem;
    height: .5rem;
    border-radius: $global-radius;
    background-color: $lightest-gray;
    overflow: hidden;
    
    span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: $global-radius;
        background-color: $primary-color;
        transition: width .3s;
    }
}

.topbar-score {
    display: inline-block;
    margin-right: 2rem;
}