@import "../../styles/settings";
@import "../../styles/mixins";

.curse-name-price {
  width: 100%;
}

.course-name {
  float: left;
}

.course-lessons {
  float: right;
  margin-right: 5rem;
}

.course-price {
  float: right;
}

@include medium-down {

}