@import '../../styles/settings';

body {
    padding-top: rem-calc(71);
}
.topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding: 0.75rem 0;
    border-bottom: 1px solid $lightest-gray;
    z-index: 10;
}
.topbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.topbar-logo {
    background-color: transparent;
    border: none;
    cursor: pointer;
    img {
        max-height: rem-calc(24);
        margin: rem-calc(11);
    }
}
.topbar-tagline {
    position: relative;
    margin-right: auto;
    margin-left: 3rem;

    &::before {
        content: '';
        position: absolute;
        left: -1.5rem;
        top: -0.5rem;
        bottom: -0.5rem;
        width: 1px;
        background-color: $light-gray;
    }
}

.topbar-test-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .topbar-tagline {
        margin-right: 1rem;
    }
}

@include medium-down {
    .topbar-logo {
        margin-right: auto;
    }
    .topbar-tagline {
        display: none;
    }
    .topbar-purchase-button {
        font-size: 0;

        i {
            margin-right: 0;
        }
    }
    .topbar {
        &.is-test {
            position: relative;
            margin-top: -4.4375rem;

            .topbar-wrapper {
                flex-wrap: wrap;
            }
            .topbar-test-wrapper {
                order: 2;
                margin: 0 0.75rem;
                margin-top: 0.5rem;
                flex-wrap: wrap;
                width: 100%;
            }
            .topbar-score {
                white-space: nowrap;
                margin-right: 0;
            }
            .topbar-tagline {
                display: block;
                margin-left: 0;
                &::before {
                    content: none;
                }
            }
            .topbar-progress {
                width: 100%;
                order: 2;
                margin-top: 0.5rem;
            }
            .topbar-progress-line {
                width: auto;
                flex-grow: 1;
            }
            .topbar-progress-value {
                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}
