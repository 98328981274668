@import "./global/global-utils";

/* --------------
- Global
----------------- */
$global-width: rem-calc(1420);
$global-lineheight: 1.5;
$global-radius: rem-calc(16);
$global-gap: 1.875rem;
$global-gap-small: 1rem;

/* --------------
- Breakpoints
----------------- */
$breakpoint-small: 0;
$breakpoint-medium: 640px;
$breakpoint-large: 1024px;

/* --------------
- Colors
----------------- */
$primary-color: #A8CD00;
$secondary-color: #00A2EA;
$tertiary-color: #FF9400;
$black: #000;
$white: #fff;
$light-primary-color: #F6FAE5;
$lightest-gray: #EFEFF1;
$light-gray: #DFDFE5;
$semi-gray: #B4B6B7;
$medium-gray: #686B6C;
$dark-gray: #4A4D4E;
$alert-color: #DD4A4A;
$success-color: #28a745;
$body-color: $black;
$body-background: #FAFAFA;
$box-shadow: 0px 6px 9px rgba($black, .05);

/* --------------
- Typography
----------------- */
$body-font-family: 'Metropolis', sans-serif;
$header-color: $dark-gray;
$header-font-family: 'Bebas Neue', sans-serif;
$header-font-weight: 500;
$header-lineheight: 1.2;
$header-h1-fontsize: (
    'large': 48,
    'medium': 36,
    'small': 32,
);
$header-h2-fontsize: (
    'large': 24,
    'medium': 24,
    'small': 24,
);
$header-h3-fontsize: (
    'large': 20,
    'medium': 20,
    'small': 20,
);

/* --------------
- Helper mixins and function
----------------- */
@mixin large {
    @media screen and (min-width: #{$breakpoint-large}) {
        @content;
    }
}
@mixin medium {
    @media screen and (min-width: #{$breakpoint-medium}) {
        @content;
    }
}
@mixin medium-only {
    @media screen and (min-width: #{$breakpoint-medium}) and (max-width: #{$breakpoint-large - 1px}) {
        @content;
    }
}
@mixin medium-down {
    @media screen and (max-width: #{$breakpoint-large - 1px}) {
        @content;
    }
}
@mixin small-only {
    @media screen and (max-width: #{$breakpoint-medium - 1px}) {
        @content;
    }
}
