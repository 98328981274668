
$names: 
    shopping-cart-primary, shopping-cart-white, basket,
    pin-primary, retry-primary, download-primary, check-primary,
    
    chevron-left-gray, chevron-right-gray, chevron-right-primary, chevron-down-primary,
    eye-crossed-gray, eye-gray,
    close-gray, close-white, close-alert,
    facebook-gray, instagram-gray,
    camera-gray,
    persons-gray,
    bin-gray, lock-gray,

    success-white, error-white, info-white, check-white,

    play-green, list-check-green, flag-green,

    english-primary, math-primary, czech-language-primary
;

.icon {

    @each $name in $names {
        &-#{$name} {
            background-image: url('../../assets/images/icons/ic-#{$name}.svg');
        }
    }
}

