@import "../../styles/settings";

@include medium {
    
    .online-course-interpretation {
        h2 {
            font-size: 2.25rem;
        }
    }
    
}