@import '../../styles/settings';

.profile-avatar {
    display: inline-block;
    margin-bottom: 1.75rem;
    width: 6rem;
    height: 6rem;
    background-color: $lightest-gray;
    border-radius: $global-radius;
    border: 1px solid $light-gray;
    background-image: url('../../assets/images/icons/ic-person-gray.svg');
    background-size: 3rem 3rem;
    background-position: center;
    background-repeat: no-repeat;
}
.profile-name {
    margin-bottom: 0;
}

.profile-edit-controls {
    display: flex;
    justify-content: space-between;
}
.profile-edit-submit {
    margin-left: auto;
}

@include small-only {
    
    .profile-edit-controls {
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .profile-edit-submit {
        order: -1;
        width: 100%;
        margin-bottom: 1rem;
    }
    
}