@import '../../styles/settings';

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.6);
    opacity: 0;
    visibility: hidden;
    z-index: 11;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    &.is-open {
        opacity: 1;
        visibility: visible;

        .modal {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
.modal {
    position: relative;
    background-color: $white;
    overflow: auto;
    max-width: 100%;
    opacity: 0;
    transform: translateY(-4rem);
    height: 670px;
    max-height: 100%;
}
.modal-close {
    position: absolute;
    top: .5rem;
    right: .5rem;
}
.modal-content {
    padding: 3rem 3.5rem;
    width: 40rem;
    max-width: 100%;
    height: 100%;
}

.remove-all-item {
    padding: 0.75rem 1.25rem;
    padding-bottom: 0.5rem;
}

.remove-all-item i {
    width: 1.1rem;
    height: 1.1rem;
}

@include small-only {
    .modal-backdrop {
        z-index: 10;
        transform: translateX(-4rem);

        &.is-open {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
    .modal {
        margin: 0 !important;
        height: 100vh;
    }
    .modal-content {
        padding: 2rem 1.5rem;
        width: 100% !important;
    }
}

@include medium {
    .modal-backdrop {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        margin: 1rem;
        border-radius: $global-radius;
        max-height: calc(100vh - 2rem);
        transition: opacity 0.3s, transform 0.3s;
        transition-delay: 0.2s;
    }
}
