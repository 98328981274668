@import "../../styles/settings";

.study-video {
}

.study-video h2 {
  color: white;
  font-size: 2.5rem;
  padding: 1rem 1rem 0 1rem;
}

.study-video p {
  color: white;
  font-size: 1rem;
  padding: 0 1rem 1rem 1rem;
  margin-top: -1rem;
}

.video-card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$global-gap-small});
  box-shadow: 0px 6px 9px rgba($black, 0.05);
  background-color: #00a2ea;
  overflow: hidden;
  transition: box-shadow 0.3s;
  margin-bottom: $global-gap-small;
  border-radius: $global-radius;
}

.study-video-player {
  max-width: 100%;
  width: 315px;
  height: 170px;
  border: 0;
  padding: 0 0.3rem;
  @include small-only {
    padding: 0 1rem 1rem;
  }
  margin: -1.5rem auto 0;
}
