@import '../../styles/settings';

.course-card {
    position: relative;
    display: block;
    height: calc(100% - #{$global-gap-small});
    box-shadow: 0px 6px 9px rgba($black, 0.05);
    border-radius: $global-radius;
    background-color: $white;
    margin-bottom: $global-gap-small;
    overflow: hidden;
    transition: box-shadow 0.3s;

    .tag {
        position: absolute;
        top: 0.75rem;
        left: 0.75rem;
    }

    &.is-primary {
        .course-card-image {
            background-color: rgba($primary-color, 0.1);
        }
    }
    &.is-secondary {
        .course-card-image {
            background-color: rgba($secondary-color, 0.1);
        }
    }

    &.is-link {
        cursor: pointer;
    }

    &.is-disabled {
        .course-card-image, .course-card-content {
            filter: grayscale(100%);
            opacity: .5;
        }
    }

    &:hover {
        box-shadow: none;
    }
}
.course-card-image {
    position: relative;
    background-size: 30% 30%;
    background-position: center;
    background-repeat: no-repeat;

    &::after {
        content: '';
        padding-bottom: 60%;
        display: block;
    }
}
.course-card-content {
    padding: 1rem;
}

.course-card-retry-payment {
    padding: 1rem 1rem 0;
    display: flex;
    justify-content: center;
}

.course-card-price {
    float: right;
    margin-top: 11.5rem;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: bold;
    color: #4A4D4E
}

.course-card-button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    background-color: $white;
    border-radius: $global-radius;
    width: 3rem;
    height: 3rem;
    border: none;
    font-size: 0;
    background-size: 40% 40%;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 6px 9px rgba($black, 0.05);
    transition: box-shadow 0.3s;
    z-index: 1;
}
button.course-card-button {
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
