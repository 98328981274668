@import "../../styles/settings";

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: $global-radius;
    height: 16.5rem;
    width: 16.5rem;
    padding: 4rem;
    text-align: center;
    box-shadow: 0px 6px 9px rgba($black, 0.05);
    cursor: progress;
}

.loader-image {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    background-image: url('../../assets/images/icons/ic-loader.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: spinning 1s linear infinite;
}

.loader-text {
    font-size: 1.125rem;
    color: $medium-gray;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    } 100% {
        transform: rotate(360deg);
    }
}