@import "../../styles/settings";

.checkbox-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: $black;
    border: 1px solid transparent;
    border-radius: $global-radius;
    background-color: $white;
    box-shadow: $box-shadow;
    cursor: pointer;
    transition: border-color .3s, box-shadow .3s;
    
    &.is-checked, &:hover {
        border-color: $primary-color;
    }

    &.is-disabled {
        opacity: .5;
        box-shadow: none;
        cursor: not-allowed;
        border: none;
    }

    &:hover {
        box-shadow: none;
    }

    span {
        display: inline-block;
        margin-right: 1rem;
    }
}