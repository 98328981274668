@import "../../styles/settings";

.button {
    font-family: $header-font-family;
    font-size:rem-calc(24);
    text-transform: uppercase;
    white-space: nowrap;
    color: $primary-color;
    line-height: 1.2;
    padding: 1.125rem 1.75rem;
    padding-bottom: 1rem;
    border-radius: $global-radius;
    border: 1px solid $primary-color;
    cursor: pointer;
    transition: background-color .3s, color .3s, border-color .3s;

    i {
        display: inline-block;
        vertical-align: middle;
        width: 1.5rem;
        height: 1.5rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: .5rem;
    }

    &.is-link {
        padding: 0;
        margin-top: calc(1.125rem + 1px);
        margin-bottom: calc(1rem + 1px);
        color: $semi-gray;
        border: none;
        border-radius: 0;
        background-color: transparent;

        &:hover {
            color: $medium-gray;
        }
    }

    &.is-primary {
        background-color: $primary-color;
        color: $white;

        &:hover:not([disabled]):not(.is-loading) {
            background-color: transparent;
            color: $primary-color;
        }
    }
    &.is-alert {
        background-color: $alert-color;
        color: $white;
        border-color: $alert-color;

        &:hover:not([disabled]):not(.is-loading) {
            background-color: transparent;
            color: $alert-color;
        }
    }
    &.is-outline {
        background-color: rgba($primary-color, .1);

        &:hover:not([disabled]):not(.is-loading) {
            background-color: transparent;
        }
    }
    &.is-transparent {
        background-color: transparent;
        border: 1px solid transparent;
        
        &:hover:not([disabled]):not(.is-loading) {
            border: 1px solid $primary-color;
        }
    }

    &.is-small {
        padding: .5rem 1rem;
        padding-bottom: .4375rem;
    }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed;
    }

    &.is-loading {
        position: relative;
        color: transparent !important;

        i {
            opacity: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 1rem);
            left: calc(50% - 1rem);
            width: 2rem;
            border-radius: 50%;
            height: 2rem;
            border: 4px solid rgba($white, .5);
            border-top-color: $white;
            animation: spinning 1s linear infinite;
        }

        &.is-outline::after, &.is-transparent::after {
            border-color: $primary-color rgba($primary-color, .3) rgba($primary-color, .3) rgba($primary-color, .3);
        }
    }
}


@keyframes spinning {
    0% {
        transform: rotate(0deg);
    } 100% {
        transform: rotate(360deg);
    }
}