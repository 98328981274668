@import "../../styles/settings";
@import "../../styles/mixins";

.user-dropdown {
    position: relative;
    margin-left: 1rem;

    &.is-open {
        .user-dropdown-button {
            &::after {
                transform: rotate(540deg);
            }
        }
        .user-dropdown-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}
.user-dropdown-button {
    position: relative;
    background-color: $lightest-gray;
    border-radius: $global-radius;
    border: 1px solid $light-gray;
    font-size: 1rem;
    color: $body-color;
    padding: .875rem 3rem;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        left: .875rem;
        top: calc(50% - 1.25rem / 2);
        width: 1.25rem;
        height: 1.25rem;
        background-image: url('../../assets/images/icons/ic-person-gray.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    &::after {
        content: '';
        position: absolute;
        right: .875rem;
        top: calc(50% - .75rem / 2);
        width: .75rem;
        height: .75rem;
        background-image: url('../../assets/images/icons/ic-chevron-down-gray.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .5s;
    }
}

.user-dropdown-menu {
    position: absolute;
    top: calc(100% + #{rem-calc(13)});
    right: 0;
    left: auto;
    min-width: 15rem;
    box-shadow: $box-shadow;
    padding: .5rem 0;
    border-radius: $global-radius;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-1rem);
    transition: opacity .3s, visibility .3s, transform .3s;

    ul {
        @include reset-list;

        a, button {
            display: block;
            padding: .75rem 1.75rem;
            color: $body-color;
            font-size: 1rem;
            line-height: 1.2;
            white-space: nowrap;
            cursor: pointer;
            transition: color .3s;

            &:hover {
                color: $primary-color;
            }
        }

        button {
            border: none;
            background-color: transparent;
            width: 100%;
            text-align: left;
        }
        .user-dropdown-light-button {
            color: $semi-gray;
        }
    }
}

@include medium-down {
    
    .user-dropdown {
        margin-left: .5rem;
    }
    .user-dropdown-button {
        font-size: 0;
        height: 2.875rem;
        padding-left: 2.375rem;
        padding-right: 2.375rem;
    }
    
}