@import "../../styles/settings";

.skeleton-loader {
    position: relative;
    overflow: hidden;
    cursor: default;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba($white, 0) 0, rgba($white, 0.2) 20%, rgba($white, 0.5) 60%, rgba($white, 0));
        animation: shimmer 1s infinite;
        z-index: 1;
    }
}

.skeleton-title, .skeleton-text {
    background-color: $lightest-gray;
    border-radius: $global-radius;
}

.skeleton-title {
    height: 1.5rem;
    width: 50%;
    margin-bottom: 1rem;
}

.skeleton-text {
    height: .875rem;
    width: 70%;
    margin-bottom: .4375rem;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}