@import '../../../styles/settings';

.test-explanation {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(calc(100% + 0.5rem));
    z-index: 3;

    &.is-open {
        .test-explanation-label {
            background-image: url('../../../assets/images/icons/ic-close-gray.svg');
            background-size: 0.75rem 0.75rem;
            background-position: 0.75rem center;
        }
        .test-explanation-text {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
}
.test-explanation-label {
    position: relative;
    background-color: $white;
    border: none;
    border-radius: 1rem;
    box-shadow: 0px 6px 9px #0000000f;
    text-decoration: underline;
    padding: 0.875rem 1rem;
    padding-left: 2rem;
    background-image: url('../../../assets/images/icons/ic-question-mark-gray.svg');
    background-size: 1.125rem 1.125rem;
    background-position: 0.5rem center;
    background-repeat: no-repeat;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 0.25rem);
        left: -0.25rem;
        transform: rotate(45deg);
        width: 0.5rem;
        height: 0.5rem;
        background-color: $white;
    }
}

.test-explanation-text {
    position: absolute;
    top: 0;
    right: calc(100% + 0.75rem);
    opacity: 0;
    background-color: $white;
    box-shadow: 0px 6px 9px #0000000f;
    border-radius: $global-radius;
    padding: 1rem 1.25rem;
    max-width: rem-calc(650);
    font-size: 0.875rem;
    border: 1px solid $lightest-gray;
    width: auto;
    visibility: hidden;
    transform: translateX(1rem);
    transition: visibility 0.3s, opacity 0.3s, transform 0.3s;

    p {
        margin-bottom: 0;
    }
}

@include small-only {
    .test-explanation-text {
        width: calc(90vw - 2rem);
    }
}
@include medium-only {
    .test-explanation-text {
        width: calc(75vw - 2rem);
    }
}
@include medium-down {
    .test-explanation {
        transform: translateY(-130%);
        right: -0.25rem;

        &.is-open {
            .test-explanation-label {
                background-position: 0.625rem center;
            }
        }
    }
    .test-explanation-label {
        font-size: 0;
        padding: 1rem;
        height: 2rem;
        &::after {
            content: none;
        }
    }
}
@include large {
    .test-explanation-text {
        width: 55vw;
    }
}
