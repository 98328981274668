@import '../../styles/settings';

.personal-courses-form-summary-price-wrapper {
    display: inline-block;
    vertical-align: top;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

@include medium {
    
    .personal-courses-form-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        &-price {
            margin-right: auto;
        }
    }
    
}