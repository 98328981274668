@import "../../styles/settings";

.tag {
    display: inline-block;
    vertical-align: top;
    padding: .125rem rem-calc(7);
    padding-bottom: rem-calc(1);
    white-space: nowrap;
    border-radius: calc(#{$global-radius} / 2);
    color: $white;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: bold;

    &.is-primary {
        background-color: $primary-color;
    }
    &.is-secondary {
        background-color: $secondary-color;
    }
    &.is-tertiary {
        background-color: $tertiary-color;
    }
    &.is-gray {
        background-color: $medium-gray;
    }
    &.is-alert {
        background-color: $alert-color;
    }
}