@import '../../styles/settings';

.image-box {
    display: flex;
    width: 100%;
    height: calc(100% - #{$global-gap-small});
    position: relative;
    padding: 1.75rem 1rem;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    box-shadow: 0px 6px 9px rgba($black, 0.05);
    border-radius: $global-radius;
    border: 1px solid transparent;
    margin-bottom: $global-gap-small;
    cursor: pointer;
    transition: box-shadow 0.3s;

    h2 {
        font-size: 1.5rem;
    }

    &.is-primary {
        .image-box-image {
            background-color: rgba($primary-color, 0.1);
        }
    }
    &.is-secondary {
        .image-box-image {
            background-color: rgba($secondary-color, 0.1);
        }
    }

    &:hover {
        box-shadow: none;
    }

    &.is-selected {
        border-color: $primary-color;
        box-shadow: none;
    }

    &.is-small {
        padding: 1.5rem 1rem;

        .image-box-content {
            padding: 1rem;
        }
    }

    &.is-disabled {
        background: #E0E0DE;
        cursor: auto;
    }

    .checkmark-wrapper {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.image-box-image {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    margin-bottom: 2.5rem;
    background-size: 40% 40%;
    background-position: center;
    background-repeat: no-repeat;
}
.image-box-title {
    margin-bottom: rem-calc(4);
}
.image-box-subtitle {
    font-size: .875rem;
    color: $black;
    margin-top: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.image-box-error {
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.classHasAlreadyBought {
    color: $primary-color;
    margin-top: -1rem;
    font-size: 1.1rem;
}

@include medium {
    
    .image-box {
        height: calc(100% - #{$global-gap});
        margin-bottom: $global-gap;
    }
    .image-box-image {
        width: 50%;
        height: auto;
        
        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }
    
}
