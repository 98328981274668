@import "../../styles/settings";

.free-course-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.75rem;
    border-radius: $global-radius;
    background-image: url('../../assets/images/backgrounds/free-course-background.svg');
    background-size: contain;
    background-position: 70%;
    background-repeat: no-repeat;
    overflow: hidden;
    color: $white;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: transparent linear-gradient(95deg, $primary-color 0%, $secondary-color 100%) 0% 0% no-repeat padding-box;
        z-index: -1;
    }

    h2, p {
        margin-bottom: 0;
        color: $white;
    }
}

@include medium {
    
    .free-course-banner {
        h2 {
            font-size: 3rem;
        }
        .someFreeLessons {
            font-size: 1.3rem;
            color: white;
        }
    }
    
}